{
  "datepicker": {
    "closeText": "Later",
    "tooltip": "Enter dates of your trip to see prices",
    "reset": "Clear dates",
    "lang": "en-US"
  },
  "meta": {
    "index": {
      "title": "Russian housing rental service - more than 100 thousand of apartments, rooms, guest houses for daily rent — Sutochno.ru",
      "description": "Sutochno.ru will help you to choose of housing in Russia and its near abroad. Our advantages are the following: no intermediaries or commission; check-in is guaranteed. A popular destination: Moscow, St. Petersburg, Kazan, Ekaterinburg, Novosibirsk, Sochi, Crimea, Abkhazia and other.",
      "keywords": "A daily apartment, an apartment on a day, a building, a cottages, a housing,a hotel, a service, a booking, no hidden fees."
    },
    "search": {
      "title": "Apartment for rent in {city}, the lease an apartment {city} Sutochno.ru",
      "description": "Rent out an apartment direct by the host without agents and an extra cost in {city}."
    },
    "detail": {
      "description": "Excellent housing at {address} in the city of {city}. Book now on Sutochno.ru",
      "perDay": "daily",
      "ad": "ad",
      "sutochno": "Sutochno.ru"
    },
    "review":{
      "title": "Rate accommodation, step 1 — Sutochno.ru"
    },
    "reviewComment":{
      "title": "Rate accommodation, step 2 — Sutochno.ru"
    },
    "reviewLocation":{
      "title": "Leave a review about the location - Sutochno.ru"
    },
    "hotel": {
      "sutochno": "Sutochno.ru",
      "price": "prices, photos, reviews",
      "booking": "Book a hotel room",
      "in": "in"
    },
    "404": {
      "title": "Page Not Found - Sutochno.ru"
    },
    "feedback": {
      "title": "Contact us — Sutochno.ru"
    }
  },
  "validation": {
    "alpha": "The may only contain alphabetic characters",
    "alpha_num": "The {field} may only contain alpha-numeric characters",
    "alpha_dash": "The {field} may contain alpha-numeric characters as well as dashes and underscores",
    "alpha_spaces": "The {field} may only contain alphabetic characters as well as spaces",
    "between": "The {field} must be between {min} and {max}",
    "confirmed": "The {field} confirmation does not match",
    "digits": "The {field} must be numeric and exactly contain {length} digits",
    "dimensions": "The {field} must be {width} pixels by {height} pixels",
    "email": "Enter a valid email address",
    "excluded": "The {field} is not a valid value",
    "ext": "The {field} is not a valid file",
    "image": "The {field} must be an image",
    "integer": "The {field} must be an integer",
    "length": "The {field} must be {length} long",
    "max_value": "The {field} must be {max} or less",
    "max": "The {field} may not be greater than {length} characters",
    "mimes": "The {field} must have a valid file type",
    "min_value": "The {field} must be {min} or more",
    "min": "The {field} must be at least {length} characters",
    "numeric": "The {field} may only contain numeric characters",
    "oneOf": "The {field} is not a valid value",
    "regex": "The {field} format is invalid",
    "required_if": "The {field} is required",
    "required": "This field is required",
    "size": "The {field} size must be less than {size}KB",
    "min_phone": "Input correct number phone",
    "settlementAccount" : "Enter the correct settlement account"
  },
  "cities": {
    "spb": "St. Petersburg",
    "msk": "Moscow",
    "sochi": "Sochi",
    "kzn": "Kazan",
    "minsk": "Minsk",
    "nn": "Nizhny Novgorod",
    "kaliningrad": "Kaliningrad",
    "krasnodar": "Krasnodar"
  }
}

<template>
  <div id="root" :class="appClasses">
      <NuxtPwaManifest />
      <FriendBonus v-if="isFriend" />
      <NuxtPage />
      <Toaster />
      <TeleportTarget id="app-modals" />
      <TeleportTarget id="app-modals-metro" />
      <TeleportTarget id="app-modals-choice" />
      <TeleportTarget id="agreement-modal" />
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import { isIOS } from "mobile-device-detect";
import { cookieParams } from "./utils/cookieParams";
import { checkPartners } from "./utils/checkPartners";
import { usePageTracker } from "./composables/gtag/index.js";
import FriendBonus from "@/components/base/FriendBonus";
import _ from "lodash";
import dimensions_ios_devices from "./assets/json/dimensions-ios-devices.json";
import useMetaBase from "@/composables/meta/base";
import useViewPort from "@/composables/global/useViewPort";

/**connecting mixin checkPartners.js for production */

const partnerMixin = process.env.NODE_ENV === "production" ? checkPartners : "";

export default {
  setup() {
    const { checkViewPort } = useViewPort();

    useMetaBase();

    if (process.env.NODE_ENV === "production") {
      usePageTracker();
    }

    onErrorCaptured((error) => {
      console.error("app onErrorCaptured:", error);
      return false;
    });

    return {
      checkViewPort,
    }
  },
  name: "App",
  components: {
    FriendBonus,
  },
  mixins: [cookieParams, partnerMixin],
  computed: {
    ...mapState("application", ["panelFriend", "req", "base", "isWebview"]),
    ...mapState("user", ["isVkApp", "baseLink", "whitelabel", "viewPort"]),
    ...mapGetters("search", ["occupied", "maxGuests"]),
    screenSize() {
      return this.viewPort;
    },
    appClasses() {
      return {
        mobile: this.viewPort === "mobile",
        "vk-app": this.isVkApp,
        desktop: this.viewPort === "desktop",
        "panel-friend": this.panelFriend && this.screenSize === "desktop",
        "is-IOS": this.isIOSDetect && this.isVkApp,
        "vk-app-main": (this.$route.path === "/") & this.isVkApp,
        "white-label": this.whitelabel,
        "white-label-domclick": this.whitelabel && this.whitelabel.name === "domclick",
        "white-label-putevka": this.whitelabel && this.whitelabel.name === "putevka",
        "white-label-russpass": this.whitelabel && this.whitelabel.name === "russpass",
        "white-label-tbank-travel": this.whitelabel && this.whitelabel.name === "tbank-travel",
        "main-page": this.$route.name === "Main",
        "white-label-leveltravel": this.whitelabel && this.whitelabel.name === "leveltravel",
        "webview": this.isWebview
      };
    },
    isTutu() {
      return this.whitelabel && this.whitelabel.name === "tutu"
    },
  },
  serverPrefetch() {
    const headers = this.$nuxt.ssrContext.event.node.req.headers;
    const ua = headers["user-agent"].toLowerCase();
    //check viewport on server side
    if (
      !/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        ua
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substr(0, 4)
      )
    ) {
      this.$store.commit("user/viewPort", "desktop");
    } else {
      this.$store.commit("user/viewPort", "mobile");
    }
  },
  watch: {
    isVkApp: function (val) {
      if (this.isVkApp === true) {
        this.onIOSDetectHandler();
      }
    },
    $route(val) {
      if (this.whitelabel && !this.isWebview && process.env.NODE_ENV === 'production') {
        if (this.whitelabel.name === "domclick") {
          ym(89152724, "hit", val.path)
        }
        if (this.whitelabel.name === "tutu") {
          ym(88488615, "hit", val.path)
        }
      }
      if (process.env.NODE_ENV === 'production') {
        ym(8562148, "hit", val.path)
      }
      if (val.name === "Detail") {
        this.parseQueryParams();
      }
    },
  },
  mounted() {
    
    // Удаляем прелоадер когда есть контент
    const load = document.getElementById("loading");
    if (load) load.remove();
    // Очистка localstorage при смене версии
    const storageVersion = localStorage.getItem("VUE_APP_VERSION");
    if (storageVersion !== process.env.VUE_APP_VERSION) {
      try {
        localStorage.clear();
      } catch (e) {}
      // Записываем новую версию
      localStorage.setItem("VUE_APP_VERSION", process.env.VUE_APP_VERSION);
    }

    //handling currency after init
    const currencyCookie = this.$cookies.get("wp_currency_id");
    const currencyCookieTitle = this.$cookies.get("wp_currency_title");
    if (currencyCookie !== null && currencyCookieTitle !== null) {
      this.$store.commit("user/currency", {
        id: currencyCookie,
        title: currencyCookieTitle,
      });
    }
    this.parseQueryParams();

    this.checkViewPort();

    this.$nextTick(function () {
      window.addEventListener("resize", this.checkViewPort);
    });

    // //https://sochi.sutochno.ru/api/
    //     HTTP.get("https://sochi.sutochno.ru/api/json/search/getFilter", {
    //       params: {
    //         locationId: 287390,
    //         locationType: "city"
    //       }
    //     })
    this.isFriend = this.$cookies.get("friend") ? true : false;
    // if(process.env.NODE_ENV === 'production') { // Можно выключить для локалки
    this.partnersDetectHandle();
    // }
    this.onIOSDetectHandler();
    if (this.whitelabel && this.whitelabel.name === "grandtrain") {
      this.switchTitle();
    }
    if (this.$route.query.webview) {
      this.setIsWebview(true)
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.checkViewPort);
  },
  methods: {
    ...mapMutations("application", ["setIsWebview", "setIsMobile"]),
    switchTitle() {
      setTimeout(() => {
        document.addEventListener("visibilitychange", () => {
          const changeTitle = () => {
            document.title =
              document.title == "!!! - Забронировать жильё"
                ? this.cacheTitle
                : "!!! - Забронировать жильё";
          };

          const switchStart = () => {
            this.intervalTitle = setInterval(changeTitle, 1000);
          };
          const switchStop = () => {
            this.initialTimout = clearTimeout(this.initialTimout);
            this.intervalTitle = clearInterval(this.intervalTitle);
          };

          if (document.visibilityState === "visible") {
            switchStop();
            if (this.cacheTitle) {
              document.title = this.cacheTitle;
            }
          } else {
            this.initialTimout = setTimeout(() => {
              this.cacheTitle = JSON.parse(JSON.stringify(document.title));
              switchStart();
            }, 300000);
          }
        });
      }, 0);
    },
    updateAvailable(e) {
      if (typeof window !== "undefined") window.location.reload();
    },
    /**
     * открывает страницу платформы во frame которая записывает партнерские куки и удалаяет параметр marker
     */
    partnersDetectHandle() {
      let params = _.fromPairs(
        location.search
          .replace(/(\?*)(.*)/, "$2")
          .split("&")
          .map((v) => v.split("="))
      );

      let urlString = new URL(window.location.href);
      let isPartnerMarker = urlString.searchParams.get("marker");
      let is_mobile = urlString.searchParams.get("is_mobile");
      this.setIsMobile(is_mobile);

      let parnterId = null;

      if (this.whitelabel) {
        parnterId = this.whitelabel.marker;
        if (this.$route.query.webview) {
          this.addQueryWebview();
        };
      } else if (this.isVkApp) {
        parnterId = 1468;
      } else if (isPartnerMarker) {
        parnterId = Number(isPartnerMarker);
      } else {
        parnterId = null;
      }

      if (isPartnerMarker || this.isVkApp || this.whitelabel) {
        let utmParams = '';
        const utmData = Object.entries(this.$route.query).filter(item => item[0].includes('utm_'));
        utmParams = utmData.length ? `&${utmData.map(item => `${item[0]}=${item[1]}`).join('&')}` : '';

        let frame = document.createElement("iframe");
        // Локально этой страницы не существет и тогда будет открываться нерабочий фрэйм и будет песконечная перезагрузка
        // По этому открываю https://sutochno.ru
        frame.setAttribute(
          "src",
          `${
            process.env.NODE_ENV === "production"
              ? this.baseLink
              : "https://sutochno.ru"
          }/help?marker=${parnterId}${utmParams}`
        );
        frame.setAttribute("height", `0`);
        frame.setAttribute("width", `0`);
        frame.setAttribute("class", `parner_frame`);
        document.getElementById("app").appendChild(frame);
      }

      if (isPartnerMarker) {
        let filtredParams = { ...params };
        delete filtredParams.marker;

        const keysParams = Object.keys(filtredParams);
        const lastParams = keysParams[keysParams.length - 1];
        let searchString = "?";
        //если в uri только ?market=
        if (Object.keys(params).length === 1 && isPartnerMarker) {
          searchString = "";
          //если в uri есть другие get параметры
        } else {
          for (const key in filtredParams) {
            if (filtredParams.hasOwnProperty(key)) {
              const element = filtredParams[key];
              if (key !== lastParams) {
                searchString = searchString + `${key}=${element}&`;
              } else {
                searchString = searchString + `&${key}=${element}`;
              }
            }
          }
        }

        const { pathname } = location;
        history.replaceState(
          null,
          document.title,
          `${pathname}${searchString}`
        );
      }
    },
    /** @description - функция обработчик ios-устройств для VK-MINI-APP  */
    onIOSDetectHandler() {
      // TODO: Nuxt проверить актуальность нужна ли функция в данное время
      this.isIOSDetect = isIOS;

      if (isIOS && typeof window !== "undefined") {
        const iphonesType = Object.keys(dimensions_ios_devices);
        const detectedModel = iphonesType.find(
          (v) =>
            window.innerWidth === dimensions_ios_devices[v].width &&
            window.innerHeight === dimensions_ios_devices[v].height
        );

        // TODO: dimensions_ios_devices уже не актуальный
        this.iosStatusBarArea =
          dimensions_ios_devices[detectedModel]?.neckline === false ? 20 : 40; //если вырез то 40пикселей отступ

        let root = document.querySelector(":root");
        root.style.setProperty("--ios-indent", `${this.iosStatusBarArea}px`);
      }

      // TODO: this.detectedModel undefined - узнать нужен ли блок
      if (isIOS & this.isVkApp && this.detectedModel) {
        /**
         * искуственный статусбар
         */
        let spacer = document.createElement("div");
        spacer.setAttribute(
          "style",
          `height: ${this.iosStatusBarArea}px;
          width: 100%;
          position: absolute;
          top: 0;
          z-index: 1110;`
        );

        let app = document.getElementById("app");
        app.before(spacer);
      }
    },
    // TODO: рефакторить
    addQueryWebview() {
      this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          let urlString = new URL(window.location.href);
          let addWebview = ""
          if (!urlString.searchParams.get("webview")) {
            addWebview = urlString.search ? "&webview=1" : "?webview=1"
          }
          setTimeout(() => {
            history.replaceState(
              null,
              document.title,
              `${urlString.pathname}${urlString.search}${addWebview}`
            );
          }, 2000)
        }
      )
    },
    parseQueryParams() {
      const isParnterGuestsCount = this.$route.query.sub_id && this.$route.query.guests;
      const GUESTS_CHILDRENS = this.$route.query.guests_childrens;
      const selectGuests = JSON.parse(localStorage.getItem("select_guests")) ?? {};

      if (
        isParnterGuestsCount ||
        this.$route.query.guests_adults ||
        GUESTS_CHILDRENS ||
        this.$route.query.pets
      ) {
        if (isParnterGuestsCount || this.$route.query.guests_adults) {
          this.$store.commit(
            "search/setAdults",
            parseInt(this.$route.query.guests ? this.$route.query.guests : this.$route.query.guests_adults)
          );
        }
        if (GUESTS_CHILDRENS) {
          let childrensList = typeof GUESTS_CHILDRENS === "string" ?
            GUESTS_CHILDRENS.split(",") : GUESTS_CHILDRENS;

          childrensList
            .map((item) => (Number(item) > 17 ? 17 : Number(item)))
            .filter(item => item >= 0);
          if (childrensList.length > 10) {
            for (let i = childrensList.length; i >= 10; i--) {
              childrensList.splice(i, 1);
            }
          }
          this.$store.commit("search/setChildrens", childrensList);
        }
        if (this.$route.query.pets) {
          this.$store.commit("detailBooking/setIsPets", true);
          this.$store.commit("detailBooking/setPets",selectGuests.pets?.description || "");
          this.$store.dispatch("search/updatePets", {
            value: true,
            description: selectGuests.pets?.description || "",
          })
        } else {
          this.$store.commit("detailBooking/setIsPets",selectGuests.pets?.value || false);
          this.$store.commit("detailBooking/setPets",selectGuests.pets?.description || "");
          this.$store.dispatch("search/updatePets", {
            value: selectGuests.pets?.value || false,
            description: selectGuests.pets?.description || "",
          })
        }
      } else {
        if (selectGuests.adults) {
          this.$store.commit("search/setAdults", selectGuests.adults);
          this.$store.commit("search/setChildrens", selectGuests.childrens || []);
          if (selectGuests.pets) {
            this.$store.commit("search/setPets", selectGuests.pets || {value: false, description: ""});
          }
        }
      }
    }
  },
  data() {
    return {
      isFriend: false,
      isIOSDetect: false,
      iosStatusBarArea: null,
      isUpdateApp: false,
      cacheTitle: null,
      intervalTitle: null,
      initialTimout: null,
    };
  },
};
</script>
<style lang="scss">
@import "assets/styles/app.scss";
</style>

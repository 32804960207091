<i18n lang="json">
{
	"ru": {
		"datepicker": {
      "reset": "Сбросить даты"
    }
	},
  "en": {
		"datepicker": {
      "reset": "Clear dates"
    }
	}
}
</i18n>
<template>
  <BaseModal
    class="sc-datepickerext"
    ref="baseModalRef"
    :is-begin="isBegin"
    :is-end="isEnd"
    :date="date"
    @next="next"
    @prev="prev"
    @inputDate="inputDate"
  >
  <div class="padding-elem" ref="padding"></div>
    <Calendar
      ref="CalendarRef"
      v-model="date"
      :begin-date="beginDate"
      :count-month="countMonth"
      orientation="horizontal"
      :change-range="changeRange"
      :type="type"
      :start-disable="startDisable"
      :end-disable="endDisable"
      :employment="employment"
      :fillMode="fillMode"
      :type-picker="typePicker"
    />
    <div class="sc-datepickerext-footer">
      <div
        data-cy="sc-datepickerext-reset"
        class="sc-datepickerext--reset"
        :class="{
          'sc-datepickerext--reset-visible':
            (date instanceof Object && (date.in || date.out)) ||
            date instanceof Date,
        }"
        @click="reset"
      >
        {{ t("datepicker.reset") }}
      </div>
    </div>
  </BaseModal>
</template>

<script>
import Calendar from "./Calendar/index.vue";
import BaseModal from "./BaseModal.vue";
import { useI18n } from 'vue-i18n';
import { mapState } from "vuex";

const BeginTimeFormat = (date = new Date()) =>
  new Date(date.getFullYear(), date.getMonth(), 1);

export default {
  name: "DatePickerExt",
  components: {
    Calendar,
    BaseModal,
  },
  props: {
    modelValue: {
      type: [Date, Object],
    },
    typePicker: {
      default: 'default',
      type: String,
      validator(value) {
        return ["search", "default"].includes(value);
      },
    },
    type: {
      default: "range",
      type: String,
      validator(value) {
        return ["single", "range"].includes(value);
      },
    },
    size: {
      default: "big",
      type: String,
      validator(value) {
        return ["small", "big"].includes(value);
      },
    },
    showHeader: {
      default: true,
      type: Boolean,
    },
    showIndicateDates: {
      default: true,
      type: Boolean,
    },
    showFooter: {
      default: true,
      type: Boolean,
    },
    startDisable: {
      type: Date,
      default: () => new Date(),
    },
    endDisable: {
      type: Date,
    },
    changeRange: {
      default: null,
      validator(value) {
        return [null, "in", "out"].includes(value);
      },
    },
    employment: {
      type: Array,
      default: () => [],
    },
    dateViews: {
      type: Object,
    },
    fillMode: {
      type: String,
      default: "in",
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      countMonth: 1,
      beginDate: BeginTimeFormat(),
      date: {},
      isMobile: true,
    };
  },
  computed: {
    ...mapState("search", ["dateFromHistorylist"]),
    isBegin() {
      if (!this.startDisable) return false;
      return this.startDisable.getTime() >= this.beginDate.getTime();
    },
    isEnd() {
      const end = new Date(JSON.parse(JSON.stringify(this.startDisable)));
      const endDate = new Date(end.setFullYear(end.getFullYear() + 1));
      const nextDate = new Date(this.beginDate).setMonth(
        this.beginDate.getMonth() + this.countMonth
      );
      return nextDate >= endDate.getTime();
    },
    // isEnd() {
    //   if (!this.endDisable) return false;
    //   const nextDate = new Date(this.beginDate).setMonth(
    //     this.beginDate.getMonth() + this.countMonth
    //   );
    //   return nextDate >= this.endDisable.getTime();
    // }
  },
  watch: {
    date: {
      deep: true,
      handler() {
        if (this.date instanceof Object && this.typePicker === 'search') {
          this.$emit("update:modelValue", this.date);
          this.$emit("change", this.date);
          return;
        }
        if (this.date instanceof Object && this.date.out && this.date.in) {
          if (this.date.in && this.date.out) {
            this.$emit("update:modelValue", this.date);
            this.$emit("change", this.date);
          }
          if (this.type !== "range") {
            this.$emit("update:modelValue", this.date);
            this.$emit("change", this.date);
          }
        }
        if (this.date instanceof Date) {
          this.$emit("update:modelValue", this.date);
          this.$emit("change", this.date);
        }
        // this.$emit('update:dateViews', this.date)
      },
    },
    modelValue: function () {
      if (this.modelValue instanceof Date) {
        this.date = this.modelValue;
      } else if (this.modelValue instanceof Object) {
        // if(!this.modelValue.in && !this.modelValue.out) {
        //   this.$set(this.date, "in", this.modelValue.in);
        //   this.$set(this.date, "out", this.modelValue.out);
        // }

        if (this.modelValue.in !== null) {
          this.date.in = this.modelValue.in;
        }
        if (this.modelValue.out !== null) {
          this.date.out = this.modelValue.out;
        }
      }
    },
    dateFromHistorylist: {
      deep: true,
      handler(val) {
        if (val instanceof Date) {
          this.date = val;
        } else if (val instanceof Object) {
          if (val.in !== null) {
            this.date.in = val.in;
          }
          if (val.out !== null) {
            this.date.out = val.out;
          }
        }
      },
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.modelValue instanceof Date) {
        // this.$set(this.date, "single", this.modelValue);
        this.date = this.modelValue;
      } else if (this.modelValue instanceof Object) {
        this.date.in = this.modelValue.in;
        this.date.out = this.modelValue.out;
      }
      this.$emit("update:dateViews", this.modelValue);
      window.addEventListener("resize", this.handleWindowResize);
    }, 0)
  },
  unmounted() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    inputDate(date) {
      this.date.in = date.in;
      this.date.out = date.out;
      this.$refs.CalendarRef.date.in = date.in;
      this.$refs.CalendarRef.date.out = date.out;
      this.$emit("update:modelValue", this.date);
      this.$emit("update:dateViews", this.date);
    },
    toggle() {
      if (this.$refs.baseModalRef.visible) this.close();
      else this.show();
    },
    show() {
      if (
        this.modelValue &&
        !this.modelValue.in &&
        !this.modelValue.out &&
        this.type === "range"
      ) {
        this.date.in = this.modelValue.in;
        this.date.out = this.modelValue.out;
      }
      this.handleWindowResize();
      if (this.modelValue instanceof Date) {
        this.beginDate = BeginTimeFormat(
          this.modelValue || this.startDisable || new Date()
        );
      } else if (this.modelValue instanceof Object) {
        this.beginDate = BeginTimeFormat(
          this.modelValue.in ||
            this.modelValue.out ||
            this.startDisable ||
            new Date()
        );
      } else {
        this.beginDate = BeginTimeFormat(this.startDisable || new Date());
      }
      this.$emit("show");
      this.$refs.baseModalRef.show();
    },
    close(value) {
      this.$emit("close", value);
      this.$refs.baseModalRef.close();
    },
    prev() {
      this.beginDate = new Date(
        new Date(this.beginDate).setMonth(
          this.beginDate.getMonth() - this.countMonth
        )
      );
    },
    next() {
      this.beginDate = new Date(
        new Date(this.beginDate).setMonth(
          this.beginDate.getMonth() + this.countMonth
        )
      );
    },
    reset() {
      if (this.date instanceof Date) {
        this.date = null;
      } else if (this.modelValue instanceof Object) {
        this.date.in = null;
        this.date.out = null;
      }
      this.$refs.CalendarRef.reset();
      this.$emit("update:modelValue", this.date);
      this.$emit("change", this.date);
      this.$emit("reset", this.date);
    },
    handleWindowResize() {
      if (
        typeof process !== "undefined" &&
        (process.server ||
          process.SERVER_BUILD ||
          (process.env && process.env.VUE_ENV === "server"))
      ) {
        this.isMobile = true;
        this.countMonth = 1;
        return;
      }
      if (window.innerWidth <= 768) {
        this.isMobile = true;
        this.countMonth = 1;
      } else {
        this.isMobile = false;
        this.countMonth = this.size == "small" ? 1 : 2;
      }
      return this.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.sc-datepickerext {
  padding: 0;
  width: 100%;
  box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08), 0px 4px 15px rgba(20, 45, 136, 0.06);
  border-radius: 20px;
  .padding-elem{
    width: 100%;
    height: 20px;
    position: absolute;
    top: 100%;
  }
  &--more {
    text-align: center;
    padding: 8px 15px;
    border: 1px solid #d1d1d1;
    display: inline-block;
    border-radius: 3px;
    float: right;
    margin-bottom: 10px;
    margin-top: -5px;
    color: #444;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
  &--reset {
    cursor: pointer;
    color: var(--color-main-black);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    height: 30px;
    padding: 0 14px;
    background: #F1F3FB;
    position: relative;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    display: inline-flex;
    align-items: center;
    border-radius: 50px;
    &-visible {
      pointer-events: auto;
      opacity: 1;
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 6px;
    right: 10px;
  }
}
</style>
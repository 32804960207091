import HTTP from "../http-common";
import searchObjectsMapping from "@/utils/searchObjectsMapping.js";
import { checkRelevanceMetrikaFlags } from "@/composables/ymab";
/**
 * Получение списка всех избранных объектов (в т.ч. архивные и недоступные для бронирования)
 *
 * @module searchObjectsByIds
 * @return {object} Promise
 */
function api(params) {
  let request = { ...params };
  if (typeof params?.occupied === "string") {
    let date = params.occupied.split(";");
    let [dateBegin, dateEnd] = date;
    request.dateBegin = dateBegin;
    request.dateEnd = dateEnd;
    delete request.occupied;
  }
  return HTTP.get("/json/search/searchObjectsByIds", {
    headers: {
      "api-version": "2.1",
    },
    params: request,
    cache: false,
  });
}

export default async (params) => {
  const updatedParams = await checkRelevanceMetrikaFlags(params);
  const newResponse = await api(updatedParams);
  const data = newResponse?.data?.data;

  if (typeof data === "object") {
    const newData = {
      total_count: data.totalCount || 0,
      objects: searchObjectsMapping(data.objects),
    };

    newResponse.data.data = newData;
  }

  return newResponse;
};


import {
  Objects,
  Hotels,
  Orders,
  Messages,
  Reviews,
  Currencies,
  HTTP,
  Offers,
  Locations,
} from "@/components/api/";
import _ from "lodash";
import { Detail } from "@/utils/gtm/";
import format from "@/utils/format";
import { getCategoryForYM, normalizedYMData } from "@/utils/yandexMetrica";

// initial state
const state = () => ({
  offersList: null,
  currentOffer: null,
  objectData: null,
  hotelData: null,
  hotelDataLoad: false,
  reviews: [],
  hotelReviews: [],
  reviewsObject: {},
  reviewsOther: null,
  commentsCount: null,
  objectPrice: null,
  isSetObjectPrice: false,
  hotelPrice: null,
  hotelObjectsId: null,
  hotelObjectsLoad: false,
  routeMap: {
    from: [],
    to: [],
    routingMode: "pedestrian",
  },
  routeMapView: false,

  titlePage: null,
  objectRating: {},
  bookingData: null,
  isModal: false,
  avgRating: null,
  hotelAvgRating: null,

  locationObject: {
    countryId: null,
    countryTitle: "",
    regionId: null,
    regionTitle: "",
    cityId: null,
    cityTitle: "",
  },
  allLocations: {
    country: null,
    region: null,
    city: null,
    countrySelected: null,
    regionSelected: null,
    citySelected: null,
  },
  locationsCntObjectsLoad: false,

  selectedSlide: 0,
  showPath: false,
  objectCardMap: null,
  visibleBtnMobile: true,

  loadingComments: true,
  loadingRatings: false,
  refForScroll: null,
  distanceSights: null,
  bedsCapacity: {
    "single_bed": 1,
    "one_n_half_bed": 1,
    "double_bed": 2,
    "king_bed": 2,
    "super_king_bed": 2,
    "chair_bed": 1,
    "yarus_bed": 2,
    "bsofa_bed": 1,
    "double_sofa_bed": 2,
    "count_sing_cot": 1,
    "count_doub_cot": 2,
    "count_sing_mat": 1,
    "count_doub_mat": 2,
    "count_sing_inf_mat": 1,
    "count_doub_inf_mat": 2,
    "count_crib": 1,
    "count_bbaby_bed": 1,
  },
});

// getters
const getters = {
  getHotelData: (state) => {
    if (!state.hotelData) return null;
    return state.hotelData.data;
  },
  getCurrentHotelObject: (state, getters) => {
    if (!getters["getHotelData"]?.objects) {
      return null;
    }
    return getters["getHotelData"].objects.find(item => String(item.id) === String(getters["getObjectData"]?.id)) || null;
  },
  hotelStars: (state, getters) => {
    if (!getters["getHotelData"]) {
      return null;
    }
    const { properties } =
      getters["getHotelData"]?.hotel?.properties?.info || {};
    if (!properties || !properties.length) {
      return null;
    }
    const starsObject = properties.find((prop) => prop.name === "stars");
    if (!starsObject || !starsObject?.value) {
      return null;
    }
    const { value } = starsObject;
    const stars = Number(/\d+/.exec(value));
    return stars;
  },
  starCert: (state, getters) => {
    if (!getters["getHotelData"]) {
      return null;
    }

    const { properties } =
      getters["getHotelData"]?.hotel?.properties?.info || {};
    if (!properties || !properties.length) {
      return null;
    }

    const result = {};

    properties.forEach((val) => {
      if (val?.name === "cert_num") {
        result["certNum"] = val.value;
      }
      if (val?.name === "cert_date") {
        result["certDate"] = val.value;
        result["isActive"] = new Date(val?.value) >= new Date();
      }
    });

    if (!Object.values(result).length) {
      return null;
    }

    return result;
  },
  isCert: (state, getters) => {
    if (!getters["starCert"]) {
      return null;
    }
    const { certDate, certNum, isActive } = getters["starCert"];
    return certDate && certNum && isActive;
  },
  getHotelReviews: (state) => {
    if (!state?.hotelData?.data?.reviews) return null;
    return state.hotelData.data.reviews;
  },
  getHotelPrice: (state) => {
    if (!state.hotelPrice) return null;
    return state.hotelPrice;
  },
  getHotelReviewsMore: (state) => {
    if (!state.hotelReviews) return null;
    return state.hotelReviews;
  },
  getObjectProperty: (state) => (propiertyName) => {
    if (!state.objectData) {
      return null;
    }

    if (state.objectData.data?.object?.properties) {
      return state.objectData.data.object.properties[propiertyName] || null;
    }
    return state.objectData.data?.propertyGroups?.find(item => item.name === propiertyName) || null;
  },
  getObjectData: (state) => {
    if (!state.objectData) return null;
    if(state.objectData?.data?.object) {
      return state.objectData?.data?.object;
    }
    return state.objectData.data;
  },
  getObjectProperties: (state, getters) => {
    if (!getters["getObjectData"] || !getters["getObjectData"].propertyGroups) {
      return null;
    }
    const { propertyGroups: props } = getters["getObjectData"];
    const result = {};
    const propertyObject = {};

    if (!props) return result;

    props.forEach((item) => {
      propertyObject[item.name] = item;
    })

    Object.keys(propertyObject).forEach((key) => {
      const item = propertyObject[key];
      result[key] = {
        title: item.title,
        properties: item.properties.filter((subProp) => {
          if (key === "accommodation_rules") {
            return subProp;
          }
          return !!subProp.value;
        }),
      };
      if (!result[key].properties.length) {
        delete result[key];
      }
    });

    return result;
  },
  getObjectOwner: (state, getters) => {
    return getters["getObjectData"]?.owner || null;
  },
  checkInOutTimeDefault: (state, getters) => {
    if (!getters["getObjectProperties"]) {
      return {};
    }
    const props = getters["getObjectProperties"]?.check_in_out_rules?.properties;
    if (!props && !(Array.isArray(props))) {
      return {};
    }
    const checkIn = props?.find((item) => item.name === "check_in");
    const checkOut = props?.find((item) => item.name === "check_out");
    if (!checkIn || !checkOut) {
      return {};
    }
    return { checkIn: parseInt(checkIn.value), checkOut: parseInt(checkOut.value) };
  },
  reducedBasicProperties: (state, getters) => {
    if (!getters["getObjectProperties"]) {
      return null;
    }
    return getters["getObjectProperties"]?.basic_properties?.properties?.reduce(
      (acc, item) => ((acc[item.name] = item.value), acc),
      {}
    );
  },
  reducedExtrabedProperties: (state, getters) => {
    if (!getters["getObjectProperties"]) {
      return null;
    }
    return getters["getObjectProperties"]?.extrabed?.properties?.reduce(
      (acc, item) => ((acc[item.name] = item.value), acc),
      {}
    );
  },
  getObjectOriginalCurrency: (state) => {
    return state.objectData?.data.currency || null;
  },
  getObjectCardMap: (state) => {
    if (!state.objectCardMap) return null;
    return state.objectCardMap;
  },
  getObjectReviews: (state) => {
    if (!state.objectData) return null;
    return state.objectData.data.reviews;
  },
  getObjectPrice: (state) => {
    if (!state.objectPrice) {
      return null;
    }
    const objectPrice = state.objectPrice.find(
      (o) => o.id === state.objectData?.data?.id || state.objectData?.data?.object?.id
    )?.data;
    if (!state.currentOffer) {
      return objectPrice;
    }
    const { payment } = state.currentOffer;
    if (!payment?.cost) {
      return objectPrice;
    }
    return { ...objectPrice, price: payment.cost };
  },
  getObjectPriceErrors: (state) => {
    if (!state.objectPrice) {
      return [];
    }
    return state.objectPrice.find(
      (o) => o.id === state.objectData.data.id
    )?.errors;
  },
  getCurrentPrice: (state) => {
    if (!state.objectData?.data.object.current_price) return null;
    return state.objectData.data.object.current_price;
  },
  getReviews: (state) => {
    if (!state.reviews) return null;
    return state.reviews;
  },
  isBronevik: (state, getters, rootState, rootGetters) => {
    const moder = rootState?.user?.userData?.user_is_moder;
    const userNoShow = rootState?.user?.userData?.user?.id;
    if (
      Number(state.objectData?.data?.object?.owner?.id) === 4784337 &&
      !(userNoShow == "4784377")
    )
      return true;
    else return false;
  },
  isHotel: (state) => {
    if (!state.objectData?.data?.hotel) {
      return null;
    }
    return Boolean(state.objectData.data.hotel.id);
  },
  beds: (state, getters) => {
    if (!getters["reducedBasicProperties"]) {
      return null;
    }
    const NAMES_BEDS = [
      "single_bed",
      "one_n_half_bed",
      "double_bed",
      "king_bed",
      "super_king_bed",
      "chair_bed",
      "yarus_bed",
      "bsofa_bed",
      "double_sofa_bed"
    ];
    return Object.entries(getters["reducedBasicProperties"] || {}).reduce(
      (acc, [key, value]) => {
        if (NAMES_BEDS.includes(key) && value > 0) {
          acc.push({
            name: key,
            value: value,
          });
        }
        return acc;
      },
      []
    );
  },
  bedsCount: (state, getters) => {
    const beds = getters["beds"]?.reduce((acc, item, index) => {
      if (!state.bedsCapacity[item.name]) {
        return acc;
      }
      return acc + (state.bedsCapacity[item.name] * item.value)
    }, 0);
    if (beds) {
      return beds;
    }
    return 0;
  },
  extraBeds: (state, getters) => {
    const NAMES_BEDS = [
      "count_sing_cot",
      "count_doub_cot",
      "count_sing_mat",
      "count_doub_mat",
      "count_sing_inf_mat",
      "count_doub_inf_mat",
      "count_crib",
      "count_bbaby_bed",
    ];
    return Object.entries(getters["reducedExtrabedProperties"] || {}).reduce((acc, [key, value]) => {
      if (NAMES_BEDS.includes(key) && value > 0) {
        acc.push({
          name: key,
          value: value,
        });
      }
      return acc;
    }, []);
  },
  extraBedsCount: (state, getters) => {
    const beds = getters["extraBeds"]?.reduce((acc, item, index) => {
      return acc + state.bedsCapacity[item.name] * item.value
    }, 0);
    if (beds) {
      return beds;
    }
    return 0
  },
  transferData: (state, getters) => {
    if (!getters["getObjectProperties"]) {
      return null;
    }

    const { included_in_price, additional_payment } =
      getters["getObjectProperties"];

    if (!included_in_price?.properties?.length) {
      return null;
    }

    const transfer = included_in_price.properties.find(
      (item) => item.name === "transfer"
    );

    if (!transfer) {
      return null;
    }

    const description = additional_payment?.properties?.filter(
      (item) => item.name === "transfer_description"
    );

    return [transfer, description];
  },
  checkObjectStatus: (state) => (statusId) => {
    return Boolean(state.objectData?.data?.status?.id === statusId);
  },
  getObjectCalendar: (state) => {
    if (!state.objectData) return null;
    return state.objectData.data.calendar;
  },
  getReviewsOther: (state) => {
    if (!state.reviewsOther) return null;
    return state.reviewsOther;
  },

  getRouteMap: (state) => {
    if (!state.routeMap || !state.routeMap.from || !state.routeMap.to)
      return null;
    return state.routeMap;
  },

  getInstant: (state) => {
    if (!state.objectPrice || !state.objectData) return null;

    const price = state.objectPrice.find(
      (o) => o.id === state.objectData.data.id
    )?.data;
    if (
      price &&
      (price.is_booking_now ||
        price.is_booking_no_precost ||
        price.is_object_hot ||
        (price.is_hot && price.object_hot.sale))
    )
      return true;
    return false;
  },
  bookingNow: (_, getters) => {
    if (!getters["getObjectPrice"]?.is_booking_now) {
      return null;
    }
    return getters["getObjectPrice"].is_booking_now;
  },
  daysBeforeCheckIn: (_, getters) => {
    const result =
      getters.getObjectProperties?.instant_rules?.properties.find(
        (el) => el.name === "before_days"
      );
    if (result) {
      return result["value"];
    }
    return false;
  },
  objectRating: (state) => {
    if (!state.objectRating) return null;
    return state.objectRating;
  },
  getBookingData: (state) => {
    if (!state.bookingData) return null;
    return state.bookingData;
  },
  getTitlePage: (state) => {
    if (!state.titlePage) return null;
    return state.titlePage;
  },
  avgRating: (state) => {
    if (!state.avgRating) return null;
    return state.avgRating;
  },
  hotelAvgRating: (state) => {
    if (!state.hotelAvgRating) return null;
    return state.hotelAvgRating;
  },
  // пояснение в actions => setSelectedSlide
  getSelectedSlide: (state, getters, rootState, rootGetters) => {
    if (rootState?.user?.isVkApp) return state.selectedSlide;
    return Number(localStorage.getItem("selectedSlide"));
  },
  // нужно для того, чтобы если у нас цена не запрашивалась, то мы взяли гостей из свойств объекта
  maxGuests: (state, getters) => {
    return (
      getters.getObjectPrice?.max_guests ||
      getters.getObjectProperties?.basic_properties?.properties?.find(
        (p) => p.name === "max_guests"
      ).value ||
      0
    );
  },
  //2 - по запросу
  //1 - разрешено
  //0 - запрещено
  getGuestRules: (state, getters) => {
    const VALID_VALUES = [0, 1, 2];
    const PROPERTIES =
      getters.getObjectProperties?.accommodation_rules?.properties;
    const PETS_VALUE = PROPERTIES?.find((p) => p.name === "pets")?.value;
    const IS_CHILDREN_VALUE = PROPERTIES?.find(
      (p) => p.name === "is_children"
    )?.value;
    const CHILDREN_AGE_VALUE = PROPERTIES?.find(
      (p) => p.name === "children_age"
    )?.value;
    const FREE_BABIES = PROPERTIES?.find(
      (p) => p.name === "free_babies"
    )?.value;
    return {
      pets: VALID_VALUES.includes(PETS_VALUE) ? PETS_VALUE : 2,
      isChildren: VALID_VALUES.includes(IS_CHILDREN_VALUE)
        ? IS_CHILDREN_VALUE
        : 2,
      childrenAge: CHILDREN_AGE_VALUE,
      freeBabies: FREE_BABIES === "false" ? false : FREE_BABIES,
    };
  },
  // пересекаются ли выбранные даты с закрытыми в календаре
  // бывают кейсы где пересечение есть во времени
  isValidDates: (state, getters) => {
    for (let key in getters.getObjectCalendar) {
      const el = getters.getObjectCalendar[key];
      const date_begin = getters.getObjectPrice.date_begin;
      const date_end = getters.getObjectPrice.date_end;
      if (
        (date_begin >= el.date_begin && date_begin <= el.date_end) ||
        (date_end >= el.date_begin && date_end <= el.date_end)
      ) {
        return false;
      }
    }
    return true;
  },
  // проверка находится ли в статусе модерации
  isInModerationStatus(state) {
    if (!state.objectData) return null;
    return (
      state.objectData.data.status.id === 2 ||
      state.objectData.data.status.id === 3
    );
  },
  getObjectPermissions(state) {
    return state?.objectData?.data?.owner?.permissions
      ? state.objectData.data.owner.permissions
      : null;
  },
  hasAlwaysAbility: (state, getters) => {
    return getters.getObjectPermissions?.includes(
      "showProcessRequestsAlwaysLabelAbility"
    );
  },
  getDataForYM: (state, getters) => (type, route) => {
    const { id, title, current_price, location, type_title, hotel } =
      getters["getObjectData"] || {};

    const { rooms } = getters["reducedBasicProperties"] || {};

    const { cnt } = getters["getObjectPrice"] || {};

    const dataForYM = normalizedYMData(
      {
        id: id || null,
        name: title || null,
        price: current_price?.cost || null,
        brand: location?.location || null,
        variant: rooms || null,
        category: getCategoryForYM({
          isHotel: getters["isHotel"],
          route: String(route).toLowerCase(),
          roomName: type_title || "",
          hotelType: hotel?.type || "",
          objectType: type_title || "",
        }),
        quantity: cnt || null,
      },
      type
    );

    return dataForYM;
  },
  getReviewsObject:(state) => {
    if (!state.reviewsObject) {
      return null;
    }
    return state.reviewsObject;
  },
  getNameObject: (state, getters) => {
    // TODO: геттер работает с двумя версиями getObject 1.10 и 2.0
    // когда все страницы перейдут на 2.0 - отрефакторить
    if (!state.objectData) {
      return null;
    }
    const objectData = state.objectData.data?.object || state.objectData?.data;
    const enterName = getters.getObjectProperty("enter_name");

    if (objectData.hotel?.id) {
      const hotelCategory = objectData.hotel_category || objectData.hotel;
      const typeTitle = objectData.type_title || objectData.name

      if (typeTitle) {
        return typeTitle;
      }
      if (typeof hotelCategory.title !== "string") {
        return "";
      }
      return `${hotelCategory?.type} ${hotelCategory?.title}`;
    }

    const findTitle = enterName?.properties
    ?.find((item) => item.name === "name_object")?.value;

    return findTitle ? String(findTitle) : "";
  },
  getCommentsCount: (state) => {
    return state.commentsCount;
  },
};

// actions
const actions = {
  async fetchOffersListToUser({ commit }, hotID) {
    const response = await Offers.getOffersListToUser(Number(hotID));
    const offersArray = response?.data?.data?.offers;
    if (offersArray) {
      commit("offersListMutate", offersArray);
    }
    return response;
  },
  setCurrentOffer({ state, commit }, offerID) {
    if (!state.offersList || !state.offersList.length) {
      return;
    }
    const currentOffer = state.offersList.find(
      (offer) => offer.id === Number(offerID)
    );
    if (!currentOffer) {
      return;
    }
    commit("currentOfferMutate", currentOffer);
  },
  getLocationsCntObjects({ commit }, formData) {
    commit("setLocationsCntObjectsLoad", true);
    return HTTP.post("json/locations/getLocationsCntObjects/", formData)
      .then((response) => {
        if (response.data.data) {
          const location = {
            countryId: null,
            countryTitle: "",
            regionId: null,
            regionTitle: "",
            cityId: null,
            cityTitle: "",
          };

          const allLocations = {
            country: null,
            region: null,
            city: null,
            countrySelected: null,
            regionSelected: null,
            citySelected: null,
          };

          if (response.data.data.country) {
            allLocations.country = response.data.data.country;
            allLocations.countrySelected = allLocations.country.findIndex(
              (item) => item.active
            );
            if (allLocations.countrySelected !== -1) {
              location.countryId =
                allLocations.country[allLocations.countrySelected]["id"];
              location.countryTitle =
                allLocations.country[allLocations.countrySelected]["title"];
            }
          }

          if (response.data.data.region) {
            allLocations.region = response.data.data.region;
            allLocations.regionSelected = allLocations.region.findIndex(
              (item) => item.active
            );
            if (allLocations.regionSelected !== -1) {
              location.regionId =
                allLocations.region[allLocations.regionSelected]["id"];
              location.regionTitle =
                allLocations.region[allLocations.regionSelected]["title"];
            }
          }

          if (response.data.data.city) {
            allLocations.city = response.data.data.city;
            allLocations.citySelected = allLocations.city.findIndex(
              (item) => item.active
            );
            if (allLocations.citySelected !== -1) {
              location.cityId =
                allLocations.city[allLocations.citySelected]["id"];
              location.cityTitle =
                allLocations.city[allLocations.citySelected]["title"];
            }
          }

          commit("setLocationObject", location);
          commit("setAllLocations", allLocations);
        }
      })
      .catch((error) => {
        console.warn("Ajax error:", error);
      })
      .finally(() => {
        commit("setLocationsCntObjectsLoad", false);
      });
  },
  setObjectData({ commit }, { id, apiVersion }) {
    return Objects.getObject(id, 1, apiVersion).then((res) => {
      // vueclient-923
      // некоторые пункты правил размещения не приходят с бека, сдесь эти пункты добавляются и со значениями по умолчанию
      const properties =
        res?.data?.data?.object?.properties?.accommodation_rules?.properties;
      if (properties) {
        const ACC_RULES = [
          { name: "smoking", defaultValue: 0 },
          { name: "pets", defaultValue: 2 },
          { name: "party", defaultValue: 2 },
          { name: "is_children", defaultValue: 1 },
          { name: "children_age", defaultValue: 0 },
          { name: "free_babies", defaultValue: 0 },
        ];
        ACC_RULES.forEach((item) => {
          let rule = properties.find((p) => p.name === item.name);
          if (!rule) {
            const deafultRule = {
              name: item.name,
              value: item.defaultValue,
            };
            properties.push(deafultRule);
          }
        });

        // if (!properties.find((p) => p.name === "is_children")) {
        //   const isChildren
        // }
      }

      commit("objectDataMutate", res.data);
      return res;
    });
  },
  setHotelData({ commit }, hotelId) {
    commit("hotelDataLoadMutate", true);
    return Hotels.getHotel(hotelId).then((res) => {
      commit("hotelDataMutate", res.data);
      commit("hotelDataLoadMutate", false);
      if (res?.data?.data?.objects.length > 0)
        commit(
          "hotelObjectsIdMutate",
          res.data.data.objects.map((item) => Number(item.id))
        );
      return res;
    });
  },
  setReviewsMore({ state, commit }, { id, limit, offset = 0, isHotel = 0, sort }) {
    return Objects.getObjectReviewsMore(id, limit, offset, isHotel, sort)
      .then((response) => {
        const data = response.data.data;
        if (isHotel) {
          commit("setReviewsObject", data);
          commit("setCommentsCount", data.commentsCount);
          commit("hotelReviewsMoreMutate", [...state.hotelReviews, ...data.comments]);
        } else {
          commit("setReviewsObject", data);
          commit("setCommentsCount", data.commentsCount);
          commit("reviewsMoreMutate", [...state.reviews, ...data.comments]);
        }
      })
      .catch((error) => {
        console.error("Ajax error: Objects.getObjectReviewsMore, ", error);
      });
  },
  setReviewsOther({ commit }, { id, page }) {
    return Reviews.getOtherReviews(id, page)
      .then((res) => {
        // const data = response.data.data;
        // this.otherReviews = [...this.otherReviews, ...data];
        // this.lastPageOther = response.data.meta.last_page;
        // this.pageOther += 1;
        // this.loadComments = false;
        const data = res.data.data;
        commit("reviewsOtherMutate", data);
      })
      .catch((error) => {
        console.log("Ajax error:", error);
      });
  },
  setObjectPrice(
    { commit, rootState },
    {
      guests,
      time_begin,
      time_end,
      date_begin,
      date_end,
      objects,
      currency_id,
      no_time = 1,
      cancelToken = null,
    }
  ) {
    commit("isSetObjectPriceMutate", false);
    const is_pets = rootState.search.guests?.pets?.value ? 1 : 0;
    const documents = rootState.search.filters?.documents ? 1 : 0;
    const target = rootState.search.filters?.party ? 4 : 0;
    const ages = toRaw(rootState.search.guests?.childrens || []);
    return Objects.getPricesAndAvailabilities({
      guests,
      ages,
      time_begin,
      time_end,
      date_begin,
      date_end,
      objects,
      currency_id,
      no_time,
      cancelToken,
      is_pets,
      documents,
      target,
    })
      .then((response) => {
        if (response.data?.errors.length) {
          throw response.data?.errors.join(" ");
        }
        const PRICE_OBJECT = response.data?.data?.objects;
        commit("objectPriceMutate", PRICE_OBJECT);
        commit("isSetObjectPriceMutate", true);
        return PRICE_OBJECT;
      })
      .catch((err) => {
        commit("isSetObjectPriceMutate", true);
        return new Promise((res, rej) => rej(err));
      });
  },
  setHotelPrice(
    { commit, getters, rootState },
    {
      guests,
      date_begin,
      date_end,
      max_rooms,
      objects,
      currency_id,
      no_time = 1,
    }
  ) {
    commit("hotelObjectsLoadMutate", true);
    const is_pets = rootState.search.guests?.pets?.value ? 1 : 0;
    const documents = rootState.search.filters?.documents ? 1 : 0;
    const target = rootState.search.filters?.party ? 4 : 0;
    const ages = toRaw(rootState.search.guests?.childrens || []);
    return Objects.getPricesAndAvailabilities({
      guests,
      ages,
      date_begin,
      date_end,
      max_rooms,
      objects,
      currency_id,
      no_time,
      is_pets,
      documents,
      target,
    })
      .then((response) => {
        const PRICES_OBJECTS = response.data?.data?.objects;
        commit("hotelPriceMutate", PRICES_OBJECTS);
        const objectPrice =
          PRICES_OBJECTS &&
          PRICES_OBJECTS.filter((item) => item.id == getters.getObjectData.id);
        if (Array.isArray(objectPrice) && objectPrice.length) {
          commit("objectPriceMutate", objectPrice);
        }
        commit("hotelObjectsLoadMutate", false);
        return PRICES_OBJECTS;
      })
      .catch((err) => {
        console.log("Ajax error: Objects.getPricesAndAvailabilities, ", err);
      });
  },
  setCurrecyPrice(
    { commit },
    {
      guests,
      time_begin,
      time_end,
      date_begin,
      date_end,
      objects,
      currency_id,
      no_time = 1,
      cancelToken = null,
    }
  ) {
    const ages = toRaw(rootState.search.guests?.childrens || []);
    return Objects.getPricesAndAvailabilities({
      guests,
      ages,
      time_begin,
      time_end,
      date_begin,
      date_end,
      objects,
      currency_id,
      no_time,
      cancelToken,
    })
      .then((res) => {
        commit("currencyPriceMutate", res?.data?.data?.objects[0]?.data);
      })
      .catch((error) => {
        console.log("Ajax error: Objects.getPricesAndAvailabilities, ", error);
      });
  },
  setObjectRating({ commit }, ratingData) {
    commit("setObjectRating", ratingData);
  },
  setRouteMap({ commit }, { from, to, routingMode }) {
    commit("routeMapMutate", { from, to, routingMode });
  },
  setBookingData(
    { commit },
    {
      phone = null,
      guests = null,
      rooms_cnt = null,
      hotel_booking = null,
      time_begin = null,
      time_end = null,
      date_begin = null,
      date_end = null,
      object_id = null,
      price = null,
      tm = null,
      is_pets = null,
      pets = null,
      target = null,
      name = null,
      email = null,
    }
  ) {
    let priceParams = {
      phone,
      guests,
      rooms_cnt,
      date_begin,
      date_end,
      time_begin,
      time_end,
      object_id,
      price,
      is_pets,
      pets,
      target,
      name,
      email,
    };

    if (hotel_booking) {
      priceParams = { ...priceParams, hotel_booking };
      delete priceParams.phone;
    }

    return Orders.createOrderDraft(priceParams)
      .then((response) => {
        if (response.data && response.data.data) {
          commit("setBookingData", response.data.data);
        }
        return response;
      })
      .catch((error) => {
        console.log(
          "Ошибка при получении данных об отеле",
          "Ajax error:",
          error
        );
      });
  },
  setTitle({ commit }, titlePage) {
    commit("setTitle", titlePage);
  },
  setAvgRating({ commit }, objectId) {
    commit("setLoadingRatings", true);
    return Messages.getAvgReviewRatingForObject(objectId)
      .then((response) => {
        if (response.data && response.data.data) {
          commit("setLoadingRatings", false);
          commit("setAvgRating", response.data.data);
        }
      })
      .catch((error) => {
        commit("setLoadingRatings", false);
        console.log(
          "Ошибка при получении рейтинга об объекте",
          "Ajax error:",
          error
        );
      });
  },
  setHotelAvgRating({ commit }, hotelId) {
    commit("setLoadingRatings", true);
    return Messages.getHotelRating(hotelId)
      .then((response) => {
        if (response.data && response.data.data) {
          commit("hotelAvgRatingMutate", response.data.data);
          commit("setLoadingRatings", false);
        }
      })
      .catch((error) => {
        commit("setLoadingRatings", false);
        console.log(
          "Ошибка при получении рейтинга об объекте",
          "Ajax error:",
          error
        );
      });
  },
  // если это приложение vk т.е. ссылки в поиске на объекте открываются в той же вкладе
  // в localStorage запись происходит уже после рендера
  // соответственно на странице деталки, selectSlide читается некорректно
  // поэтому, если это vkApp записи нужно делать в state
  setSelectedSlide(
    { dispatch, commit, state, rootState, getters, rootGetters },
    index
  ) {
    if (rootState?.user?.isVkApp) return commit("setSelectedSlide", index);
    return localStorage.setItem("selectedSlide", index);
  },
  onSubmitAnalytics({ state, getters, rootState }) {
    Detail.gaEventBooking(
      getters.getObjectData.location.location,
      getters.getObjectPrice.price
    );
    // https://jira.webpower.ru/browse/VUECLIENT-1811
    // Detail.gaAddProduct(
    //   format(rootState.search.checkIn),
    //   format(rootState.search.checkOut),
    //   getters.getObjectData.title,
    //   getters.getObjectData.id,
    //   getters.getObjectData.extprices?.find((item) => item.interval_start == 0)
    //     ?.value || "?",
    //   getters.getObjectData.location.location,
    //   getters.getObjectData.type_title.charAt(0).toUpperCase() +
    //     getters.getObjectData.type_title.substr(1),
    //   `номеров: ${
    //     getters.getObjectData.properties?.basic_properties?.properties?.find(
    //       (item) => item.name == "rooms"
    //     )?.value || 1
    //   }`,
    //   getters.getObjectData.position,
    //   getters.getObjectPrice.cnt
    // );
  },
  setRates({ commit }, id) {
    return Currencies.getRates(id)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        console.log("Ошибка при получении валют", error);
      });
  },
  getDistanceSights({ commit }, id) {
    Locations.getDistanceSights(id)
      .then((response) => {
        commit("setDistanceSights", response?.data?.data);
      })
      .catch((error) => {
        console.log("Ошибка getDistanceSights", error);
      });
  }
};

// mutations
const mutations = {
  offersListMutate(state, payload) {
    state.offersList = payload;
  },
  currentOfferMutate(state, offer) {
    state.currentOffer = offer;
  },
  hotelObjectsLoadMutate(state, value) {
    state.hotelObjectsLoad = value;
  },
  hotelDataMutate(state, value) {
    state.hotelData = value;
  },
  hotelDataLoadMutate(state, value) {
    state.hotelDataLoad = value;
  },
  hotelObjectsIdMutate(state, value) {
    state.hotelObjectsId = value;
  },
  objectDataMutate(state, value) {
    state.objectData = value;
  },
  hotelPriceMutate(state, value) {
    state.hotelPrice = value;
  },
  objectPriceMutate(state, value) {
    state.objectPrice = value;
  },
  isSetObjectPriceMutate(state, payload) {
    state.isSetObjectPrice = payload;
  },
  cntObjectPriceMutate(state, value) {
    if (
      state.objectPrice &&
      state.objectPrice[state.objectData.data.object.id] &&
      state.objectPrice[state.objectData.data.object.id].data &&
      state.objectPrice[state.objectData.data.object.id].data.error
    )
      state.objectPrice[state.objectData.data.object.id].data.error = null;
  },
  reviewsMoreMutate(state, value) {
    state.reviews = value;
  },
  hotelReviewsMoreMutate(state, value) {
    state.hotelReviews = value;
  },
  setReviewsObject(state, value) {
    state.reviewsObject = value;
  },
  reviewsOtherMutate(state, value) {
    state.reviewsOther = Object.values(value).reverse();
  },
  setObjectRating(state, value) {
    state.objectRating = value;
  },
  routeMapMutate(state, value) {
    state.routeMap = value;
  },
  routeMapViewMutate(state, value) {
    state.routeMapView = value;
  },
  setBookingData(state, value) {
    state.bookingData = value;
  },
  setModal(state, value) {
    state.isModal = value;
  },
  setTitle(state, value) {
    state.titlePage = value;
  },
  setAvgRating(state, value) {
    state.avgRating = value;
  },
  hotelAvgRatingMutate(state, value) {
    state.hotelAvgRating = value;
  },
  setLocationObject(state, value) {
    state.locationObject = value;
  },
  setAllLocations(state, value) {
    state.allLocations = value;
  },
  setLocationsCntObjectsLoad(state, value) {
    state.locationsCntObjectsLoad = value;
  },
  setSelectedSlide(state, value) {
    state.selectedSlide = value;
  },
  setShowPath(state, value) {
    state.showPath = value;
  },
  setObjectMap(state, value) {
    state.objectCardMap = value;
  },
  setVisibleBtnMobile(state, value) {
    state.visibleBtnMobile = value;
  },
  setLoadingComments(state) {
    state.loadingComments = false;
  },
  setLoadingRatings(state, value) {
    state.loadingRatings = value;
  },
  setRefForScroll(state, value) {
    state.refForScroll = value;
  },
  setDistanceSights(state, value) {
    state.distanceSights = value;
  },
  setCommentsCount(state, value) {
    state.commentsCount = value;
  },
};

export default {
  namespaced: true,
  namespace: "detail",
  state,
  getters,
  actions,
  mutations,
};

import { Objects, Search } from "@/components/api/";

// initial state
const state = () => ({
  favoritesInLocations: [],
  currentFavoritesInLocations: [],
  favoriteObjects: [],
  favoritesMapBoundaries: {
    'NE.lat': null,
    'NE.lng': null,
    'SW.lat': null,
    'SW.lng': null,
  },
  availableObjectIds: [],
  unavailableObjectIds: [],
});

// getters
const getters = {
  getFavoritesInCurrentLocation: (state, getters, rootState) => {
    return rootState.user.favoritesList.find(item => item.cityId === rootState.search.selectedVariant?.id) || {};
  },
  userFavoritesForMap: (state, getters, rootState) => {
    if (getters.getFavoritesInCurrentLocation?.objectsCount) {
      return getters.getFavoritesInCurrentLocation.objects.map((favorite => {
        return {
          id: favorite.objectId,
          lat: favorite.lat,
          lng: favorite.lng,
          price: 0,
          salePrice: 0,
        }
      }));
    }
    return [];
  },
  getAllFavoriteIdsInLocation: (state, getters, rootState) => {
    return getters.userFavoritesForMap?.map(item => item.id) || [];
  },
};

// mutations
const mutations = {
  setFavoritesInLocations(state, value) {
    state.favoritesInLocations = value;
  },
  setCurrentFavoritesInLocations(state, value) {
    state.currentFavoritesInLocations = value;
  },
  setFavoriteObjects(state, value) {
    state.favoriteObjects = value;
  },
  setFavoritesMapBoundaries(state, value) {
    state.favoritesMapBoundaries = value
  },
  setAvailableObjectIds(state, value) {
    state.availableObjectIds = value;
  },
  setUnavailableObjectIds(state, value) {
    state.unavailableObjectIds = value;
  }
};

// actions
const actions = {
  async getFavoritesInLocations({ commit, getters }, locationId = null) {
    try {
      const response = await Objects.getFavoritesInLocations();
      const favoritesObject = response?.data?.data?.favorites;
      commit('setFavoritesInLocations', favoritesObject);
      if(locationId) {
        const favoritesInLocation = getters.getFavoritesInCurrentLocation(locationId);
        commit('setCurrentFavoritesInLocations', favoritesInLocation);
      }
    } catch(e) {
      console.error(e);
    }
  },
  async searchObjectsByIds({ commit, getters }, params) {
    try {
      const response = await Search.searchObjectsByIds(params);
      commit('setFavoriteObjects', response?.data?.data?.objects || []);
      return response?.data?.data?.objects || [];
    } catch(e) {
      console.error(e);
    }
  }
};

export default {
  namespaced: true,
  namespace: "favorites",
  state,
  getters,
  actions,
  mutations,
};

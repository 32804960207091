import getObject from "./getObject";
import getObjectReviewsMore from "./getObjectReviewsMore";
import getPricesAndAvailabilities from "./getPricesAndAvailabilities";
import getPricesAndAvailabilitiesBronevik from "./getPricesAndAvailabilitiesBronevik";
import getPrices from "./getPrices";
import toggleFavorite from "./toggleFavorite";
import getObjectListUser from "./getObjectListUser";
import getProperties from "./getProperties";
import getFavoritesList from "./getFavoritesList";
import getMedia from "./getMedia";
import getObjectLocation from "./getObjectLocation";
import getObjectsUser from "./getObjectsUser";
import getFavoritesInLocations from "./getFavoritesInLocations";

/**
 * Работа с пользовательскими данными
 *
 * @module Users
 */
export default {
  getObject,
  getObjectReviewsMore,
  getPricesAndAvailabilities,
  getPricesAndAvailabilitiesBronevik,
  getPrices,
  toggleFavorite,
  getObjectListUser,
  getProperties,
  getFavoritesList,
  getMedia,
  getObjectLocation,
  getObjectsUser,
  getFavoritesInLocations,
};

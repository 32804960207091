import HTTP from "../http-common";

/**
 * Получение списка избранных
 *
 * @module getFavoritesInLocations
 * @return {object} Promise
 */
export default () =>
  HTTP.get("json/objects/getFavoritesInLocations", {
    headers: {
      "api-version": 2.1, // Если указать версию, то будет минимум данных
    },
    cache: false,
  });
